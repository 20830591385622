<template>
  <BasePageList
    page-name="Товарные знаки"
    :showed-model-types="modelTypes"
  >
  </BasePageList>
</template>

<script src="./PageTrademarkList.ts" lang="ts"/>
<style src="./PageTrademarkList.scss" lang="scss"/>
