import { defineComponent } from 'vue';
import BasePageList from '@/components/Model/BasePageList/BasePageList.vue';
import ModelList from '@/components/Model/ModelList/ModelList.vue';
import { RegisteredModelTypes } from '@/types/modelType';

export default defineComponent({
  name: 'PageInventionList',

  components: {
    BasePageList,
    ModelList
  },

  setup() {
    const modelTypes = [RegisteredModelTypes.TRADEMARK];
    return { modelTypes };
  }
});
